import React, { Fragment, ReactElement } from 'react';
import { Box } from 'theme-ui';
import DesktopTemplate from './components/DesktopTemplate';
import MobileTemplate from './components/MobileTemplate';

const CxWiseSectionWordsToWise = (props): ReactElement => {
  return (
    <Fragment>
      <Box
        sx={{
          display: ['none', 'none', 'none', 'flex'],
        }}
      >
        <DesktopTemplate {...props} />
      </Box>
      <Box
        sx={{
          display: ['flex', 'flex', 'flex', 'none'],
        }}
      >
        <MobileTemplate {...props} />
      </Box>
    </Fragment>
  );
};

export default CxWiseSectionWordsToWise;
