import { RichText, SocialShare } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import React, { ReactElement } from 'react';
import { Box, Flex } from 'theme-ui';
import Author from './Author';

const DesktopTemplate = (props): ReactElement => {
  const {
    title,
    description,
    content,
    topMargin,
    bottomMargin,
    author,
    sx,
    socialShareContent,
  } = props;
  const padding = {
    top: topMargin ?? 'MEDIUM',
    bottom: bottomMargin ?? 'MEDIUM',
  };
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        pt,
        pb,
        ...sx,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Author {...author[0]} />
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          flex: 1,
          gap: '20px',
        }}
      >
        <Box>
          <Box
            sx={{
              fontSize: ['36px', '36px', '48px', '48px'],
              lineHeight: ['44px', '44px', '58px', '58px'],
              textTransform: 'uppercase',
              fontWeight: 800,
            }}
            as="h2"
          >
            {title}
          </Box>
          <RichText richTextObject={description} />
        </Box>
        <Box
          sx={{
            maxWidth: '80%',
            p: {
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '20px',
              color: '#000',
            },
          }}
        >
          <RichText richTextObject={content} />
        </Box>
        <Flex sx={{ gap: '20px', alignItems: 'center' }}>
          <Box
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: 700,
            }}
          >
            share:{' '}
          </Box>
          <SocialShare content={socialShareContent?.socialShareContent} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DesktopTemplate;
