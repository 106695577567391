import { RichText, SocialShare } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import React, { ReactElement } from 'react';
import { Box, Flex } from 'theme-ui';
import Author from './Author';

const MobileTemplate = (props): ReactElement => {
  const {
    title,
    description,
    content,
    topMargin,
    bottomMargin,
    author,
    socialShareContent,
    sx,
  } = props;
  const padding = {
    top: topMargin ?? 'MEDIUM',
    bottom: bottomMargin ?? 'MEDIUM',
  };
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        pt,
        pb,
        flexDirection: 'column',
        gap: '20px',
        ...sx,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: ['36px', '36px', '48px', '48px'],
            lineHeight: ['44px', '44px', '58px', '58px'],
            fontWeight: 800,
            textTransform: 'uppercase',
          }}
          as="h2"
        >
          {title}
        </Box>
        <RichText richTextObject={description} />
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Author {...author[0]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingX: '40px',
          p: {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            color: '#000',
          },
        }}
      >
        <RichText richTextObject={content} />
      </Box>
      <Flex sx={{ gap: '20px', alignItems: 'center' }}>
        <Box
          sx={{
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 700,
            color: '#A9A9A9',
          }}
        >
          share:{' '}
        </Box>
        <SocialShare content={socialShareContent?.socialShareContent} />
      </Flex>
    </Flex>
  );
};

export default MobileTemplate;
