import { ImageBlock } from '@sprinklr/shared-lib';
import React, { ReactElement } from 'react';
import { Box, Flex } from 'theme-ui';

const Author = ({ title, description = '', images }): ReactElement => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(163.86deg, #70BF54 14.48%, #00BAE9 88.78%)',
        width: '40vw',
        height: '40vw',
        borderRadius: '50%',
        transform: 'translateX(-50%)',
        position: 'relative',
      }}
    >
      <Flex
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: ['translate(80%, -50%)', 'translate(70%, -50%)'],
          flexDirection: ['row', 'row', 'row', 'column'],
          gap: '10px',
          alignItems: 'center',
          width: 'max-content',
        }}
      >
        <Box
          sx={{
            width: '25vw',
            height: '25vw',
            position: 'relative',
          }}
        >
          <ImageBlock
            image={images[0]}
            altTitle={images[0].title}
            imageSx={{ objectFit: 'cover', borderRadius: '50%' }}
          />
          <ActiveCircle />
        </Box>

        <Flex
          sx={{
            flexDirection: 'column',
            fontSize: ['16px', '16px', '18px', '18px'],
            lineHeight: '22px',
            letterSpacing: '2.5px',
            position: 'relative',
            right: ['0', '-20%'],
          }}
        >
          <Box sx={{ fontWeight: 700 }}>{title}</Box>
          <Box>
            {description.split(',').map((text, i) => (
              <Box
                sx={{
                  display: 'block',
                }}
                as="span"
              >
                {text}
                {i !== description.split(',').length - 1 && ','}
              </Box>
            ))}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

const ActiveCircle = (): ReactElement => (
  <Box
    sx={{
      position: 'absolute',
      right: 0,
      top: '15%',
      background: '#00BAE9',
      width: '15%',
      height: '15%',
      borderRadius: '50%',
    }}
  ></Box>
);

export default Author;
